import React from "react"
import { Container, ThemeProvider, Typography } from "@mui/material"
import theme from "../theme.js"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Data = () => {
  return (
    <ThemeProvider theme={theme}>
      <Seo title={"Vinkoll - Hantera din data"} />
      <Layout isHome={false}>
        <Container className="container">
          <Typography variant="h3" component="h3" gutterBottom>
            Hantera din data
          </Typography>
          <Typography variant="body1" gutterBottom>
            För att ta bort din data från Vinkolls databas, skicka ett mail till{" "}
            <a href="mailto: hej@vinkoll.se">hej@vinkoll.se</a> från den
            epostadress som användaren är kopplad till.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Den data som tas bort vid en förfrågan om borttagning är:
            <ul>
              <li>Användarnamn (epostadress)</li>
              <li>Lösenord </li>
              <li>För- och efternamn</li>
              <li>Postnummer</li>
              <li>Enhets-ID för pushnotiser</li>
            </ul>
          </Typography>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default Data
